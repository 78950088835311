






















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: [] }) decorators
  @Prop({ default: true }) showSilhouette
}
